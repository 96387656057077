import { Dispatch } from 'redux'
import { PostDogNames } from 'state/apis/admin/dognamesApi'
import { Action, ActionType } from '../../types/admin/dognamesType'

export const postDogNames = dogNames => {
  return async (_dispatch: Dispatch<Action>) => {
    try {
      const resp = await PostDogNames(dogNames)
      return resp
      // console.log({resp})

      // download(new Blob(resp), "CUSTOM_NAME.pdf");
      // dispatch({
      //   type: ActionType.POST_DOGNAMES,
      //   payload: dogNames
      // })

      // fetch('/download', {
      //   method: 'POST',
      //   body: JSON.stringify(body),
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      // }).then(function(resp) {
      //   return resp.blob();
      // }).then(function(blob) {
      //   return download(blob, "CUSTOM_NAME.pdf");
      // });
    } catch (err) {
      console.log(err)
    }
  }
}

import { parse } from 'papaparse' // for parsing csv
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { postDogNames } from 'state/actions/admin/dognamesAction'
import { useFilePicker } from 'use-file-picker'
// import dayjs from 'dayjs'

// TODO
// - solve the double submit
// - tidy (+ uninstall npm mods)

export default function Dognames() {
  const [openFileSelector, { filesContent }] = useFilePicker({
    accept: '.csv',
    limitFilesConfig: { min: 1, max: 1 }
  })

  const dispatch = useDispatch()
  // const [highlighted, setHighlighted] = useState(false)
  // const [dogNames, setDogNames] = useState([])

  const [dogs, setDogs] = useState('')

  // function getDogs(e: { preventDefault: () => void }) {
  //   setDogs(dogNames.map(dog => dog.dogName).join('\n'))
  //   e.preventDefault()
  // }
  // const [dogs2, setDogs2] = useState([])
  // const [embed, setEmbed] = useState('')

  const generatePdf = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setSuccess(false)
    setLoading(true)
    try {
      const _resp = await dispatch(postDogNames(dogs.split('\n')))
    } catch (err) {
      console.error(JSON.stringify(err, null, 2))
    } finally {
      setSuccess(true)
      setLoading(false)
    }
    // @ts-ignore
    // fileDownload(resp, `dognames_${dayjs().format('YY-MM-DD')}.pdf`)

    // window.open(resp);
    // window.open(`data:application/pdf;base64,${resp}`);
    // // alert(1)

    // setEmbed(`data:application/pdf;base64,${resp}`)
    // setEmbed(`${resp}`)
    // // alert(2)

    // const linkSource =`data:application/pdf;base64,${resp}` //  `${resp}`
    // const downloadLink = document.createElement("a");
    // const fileName = `dognames_${dayjs().format('YY-MM-DD')}.pdf`
    // downloadLink.href = linkSource;
    // downloadLink.download = fileName;
    // downloadLink.click();

    // const url = window.URL.createObjectURL(new Blob([resp]));
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', `dognames_${dayjs().format('YY-MM-DD')}.pdf`); //or any other extension
    // document.body.appendChild(link);
    // link.click();
  }

  const [isHovering1, setIsHovering1] = useState(false)
  const [isHovering2, setIsHovering2] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visibility, setVisibility] = useState(false)

  useEffect(() => {
    dogs === '' ? setVisibility(false) : setVisibility(true)
  }, [dogs])

  useEffect(() => {
    if (filesContent?.length) {
      setVisibility(true)
      // handle csv format
      const { content } = filesContent[0]
      console.log({ content })
      const { data } = parse(content)
      console.log({ data })
      // const data = content.split('\r\n').map(row => row.split(','))
      data.shift()
      const column = data[0][1] ? 1 : 0
      const names = data
        .map(row => row[column])
        .filter(name => name)
        .map(name => {
          const n = name
            .replace(/&/g, ',')
            .replace(/\s+/g, ' ')
            .split(',')
            .map(n => n.trim())
            .filter(n => n)
            .map(n => {
              // General tidy
              n = n[0].toUpperCase() + n.substring(1).toLowerCase()

              // Deal with spaces/hyphens in names
              if (n.includes(' '))
                n = n
                  .split(' ')
                  .map(m => `${m[0].toUpperCase()}${m.substring(1).toLowerCase()}`)
                  .join(' ')

              if (n.includes('-'))
                n = n
                  .split('-')
                  .map(m => `${m[0].toUpperCase()}${m.substring(1).toLowerCase()}`)
                  .join('-')

              return n
            })
          name = n.join(', ').replace(/,(?!.*,)/gim, ' &')
          return name
        })

      // TODO
      // - fix case, first upper, then lower MAX => Max         order.status[0].toUpperCase() + order.status.substring(1).toLowerCase()
      // - filter bad characters, whitelist: a-zA-Z\- &,   (remove numbers, special chars @'"*)

      console.log({ names })

      // set dogs2
      // setDogs2(names)
      setDogs(names.join('\n'))
      setSuccess(false)
    }
  }, [filesContent])

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      {/* {filesContent.map((file, index) => (
            <div>
              <div key={index}>{file.content}</div>
              <br />
            </div>
          ))} */}

      {/* { dogs2 && <div>{dogs2.join('\n')}</div>} */}

      <div style={{ textAlign: 'center', width: '100%' }}>
        <br />
        <h1>Dogs Names</h1>
        <br />
        <form>
          <textarea
            data-cy="file-input"
            data-testid="textarea"
            style={{ minHeight: '300px', minWidth: '300px', borderRadius: '5px', border: '3px solid #c6dabb' }}
            value={dogs}
            onChange={e => setDogs(e.target.value)}
          ></textarea>
          <br />

          <div
            data-testid="selectCsv"
            onMouseEnter={() => setIsHovering1(true)}
            onMouseLeave={() => setIsHovering1(false)}
            style={{
              cursor: 'pointer',
              backgroundColor: isHovering1 ? 'salmon' : '#88898a',
              marginTop: '10px',
              width: '150px',
              padding: '5px',
              borderRadius: '5px',
              color: 'white',
              margin: '20px auto 0'
            }}
            onClick={() => openFileSelector()}
          >
            Select CSV
          </div>

          {visibility && (
            <button
              data-testid="createPdf"
              onMouseEnter={() => setIsHovering2(true)}
              onMouseLeave={() => setIsHovering2(false)}
              style={{
                backgroundColor: isHovering2 && !loading ? '#006685' : '#88898a',
                margin: '10px',
                width: '150px',
                padding: '5px',
                borderRadius: '5px',
                color: 'white'
              }}
              onClick={generatePdf}
              disabled={loading}
            >
              Create PDF
            </button>
          )}
          {visibility && success && <div data-testid="emailSent">Your PDF will be emailed shortly!</div>}
        </form>

        {/* {embed && <embed style={{width: '100%', height: '500px'}} src={embed} />} */}
      </div>
    </div>
  )
}

import Api from '../api'

export function PostDogNames(dogNames) {
  console.log({ dogNames })
  return new Promise<any>((resolve, reject) => {
    Api.post('wwwREST', '/v1/private/admin/dognames?1=1', {
      // responseType: 'blob',
      body: { dogNames },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf'
      }
    }) // 1=1 hacky, ask Dean
      .then(res => resolve(res))
      .catch(err => reject(err.message))
  })
}
